import {
  AfterViewInit,
  Component,
  ElementRef,
  HostListener,
  inject,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  AnimationVidPaths,
  DBItem,
  DBItemColors,
  DBItemIconPaths,
  DBItems,
  QUOTE_EN,
  QUOTE_TH,
  SavedDashboard,
  SavedDashboardValue,
} from '@core/components/dashboard-container/dashboard-container.constant';
import { TranslateService } from '@ngx-translate/core';
import { SavedDashboardService } from '@core/components/dashboard-container/saved-dashboard.service';
import { Tab } from '@core/components/dashboard-container/saved-dashboard-slider/saved-dashboard-slider.component';
import { Lang } from '@shared/utils/utils';
import { DeviceType } from '@shared/models/common.model';
import { DisplayService } from '@core/services/display.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-saved-dashboard',
  templateUrl: './saved-dashboard.component.html',
  styleUrls: ['./saved-dashboard.component.scss'],
})
export class SavedDashboardComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  quote_th = QUOTE_TH;
  quote_en = QUOTE_EN;
  quote_rd_in: number;

  tabs: Tab[] = [];
  lmTree: SavedDashboardValue;

  deviceType: DeviceType = 'desktop';
  private displayService = inject(DisplayService);
  private subscription = new Subscription();

  @ViewChild('videoContainer') set onVideoContainerChange(
    elRef: ElementRef<HTMLDivElement>,
  ) {
    this.videoContainerElRef = elRef;
  }

  videoContainerElRef?: ElementRef<HTMLDivElement>;
  scale: string;
  show = false;
  videoPath: string;

  constructor(
    private translate: TranslateService,
    private savedDashboardService: SavedDashboardService,
  ) {
    this.quote_rd_in = Math.floor(
      Math.random() * this.quote_th.length,
    );
  }

  ngOnInit(): void {
    this.observeDisplay();
    this.savedDashboardService
      .getSavedDashboard()
      .subscribe((res: SavedDashboard) => {
        this.lmTree = res.last_month_tree;
        this.setVideoPath(+res.page_count.value);
        const dbKeys = Object.values(DBItems);
        Object.keys(res).forEach((key: any) => {
          if (dbKeys.includes(key)) {
            const _key = key as DBItem;
            const obj = res[_key];
            this.tabs.push({
              value: obj.value,
              unit: obj.unit,
              color: DBItemColors[_key],
              iconPath: DBItemIconPaths[_key],
              title: `SAVED-DB.${_key}`,
            });
          }
        });
      });
  }

  observeDisplay(): void {
    const sub = this.displayService
      .getUnderBreakpointObserver()
      .subscribe({
        next: (res) => {
          if (res.sm) {
            this.deviceType = 'mobile';
          } else if (res.md || res.lg) {
            this.deviceType = 'tablet';
          } else {
            this.deviceType = 'desktop';
          }
        },
      });
    this.subscription.add(sub);
  }

  setVideoPath(paper: number) {
    for (const [maxPage, vidPath] of Object.entries(
      AnimationVidPaths,
    )) {
      if (paper <= +maxPage) {
        this.videoPath = vidPath;
        break;
      }
    }
    if (!this.videoPath) {
      this.videoPath = 'assets/video/ns9-1920x1080.mp4';
    }
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.calculateTransform();
    }, 300);
  }

  @HostListener('window:resize', ['$event'])
  onResize(): void {
    this.show = false;
    setTimeout(() => {
      this.calculateTransform();
    }, 300);
  }

  calculateTransform() {
    const videoW = 1846;
    const videoH = 1170;
    const elRef = this.videoContainerElRef?.nativeElement;
    if (elRef) {
      const containerW = elRef.clientWidth;
      const containerH = elRef.clientHeight;
      const videoWidth = videoW * (containerH / videoH);
      const scale = Math.max(1, containerW / videoWidth);
      this.scale = `scale(${scale})`;
    } else {
      this.scale = `scale(1)`;
    }
    this.show = true;
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  get lang(): Lang {
    return this.translate.currentLang as Lang;
  }
}
