import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as pdfjsLib from 'pdfjs-dist';
import { AppModule } from './app/app.module';
import { environment, featureFlag } from './environments/environment';
import { API_URL } from '@shared/service/api.constant';
import { SiteConfigObj } from '@shared/service/site-config-service';

/**
 * In case `pdf.worker.js` version < `2.9.359`.
 * We need to set custom pdf worker path for enabling digital signature in pdf.
 * More info: https://stackoverflow.com/questions/57380989/preview-pdf-file-with-e-signature-in-angular
 * We can download PDF worker js file from https://cdn.jsdelivr.net/npm/pdfjs-dist@{PDF_WORKER_VERSION_HERE}/legacy/build/pdf.worker.min.js
 *
 * NOTE: `pdfjs-dist` module required a same minor version for `pdf.worker.js`.
 *
 * This below is setting up custom pdf.worker.js path.
 */
const workerSrc = '/assets/scripts/pdf.worker-4.8.69.mjs';
(<any>window).pdfWorkerSrc = workerSrc;
pdfjsLib.GlobalWorkerOptions.workerSrc = workerSrc;

if (environment.production) {
  enableProdMode();
}

(async () => {
  // this api does not require authentication.
  const response = await fetch(
    environment.baseUrl + API_URL.site_config,
  );
  const config: SiteConfigObj = await response.json();

  featureFlag.memo_enable = config.memo_enable;
  featureFlag.econtract_enable = config.econtract_enable;
  featureFlag.ddoc = config.ddoc_enable;
  featureFlag.ddoc_enable_feature_26 = config.ddoc_enable_feature_26;
  featureFlag.ddoc_enable_feature_28 = config.ddoc_enable_feature_28;
  featureFlag.timestamp_memo_enable = config.timestamp_memo_enable;
  featureFlag.login_adfs_enable = config.login_adfs_enable;
  featureFlag.thaid_login_enable = config.thaid_login_enable;
  featureFlag.maintenance_mode = config.maintenance_mode;
  featureFlag.maintenance_message_th = config.maintenance_message_th;
  featureFlag.maintenance_message_en = config.maintenance_message_en;
  featureFlag.ndid = config.ndid_enable;
  featureFlag.sms_otp = config.sms_otp_enable;
  featureFlag.email_otp = config.email_otp_enable;
  featureFlag.thaicom = config.thaicom_enable;
  featureFlag.saved_filter_enable = config.saved_filter_enable;
  featureFlag.self_service_form = config.self_service_form_enable;
  featureFlag.auto_widget_enable = config.auto_widget_enable;
  featureFlag.google_client_id = config.google_client_id;
  featureFlag.lark_app_id = config.lark_app_id;
  featureFlag.export_memo_google_drive_enable =
    config.export_memo_google_drive_enable;
  featureFlag.media_url_need_authentication =
    config.media_url_need_authentication;
  featureFlag.share_folder_enable = config.share_folder_enable;
  featureFlag.multi_approval_enable = config.multi_approval_enable;
  featureFlag.package_name = config.package_name;
  featureFlag.is_on_premise = config.is_on_premise;
  featureFlag.ddoc_root_cert_company_name =
    config.ddoc_root_cert_company_name;
  featureFlag.company_name = config.company_name;
  featureFlag.carbon_accounting_enable =
    config.carbon_accounting_enable;
  featureFlag.download_detail_enable = config.download_detail_enable;
  featureFlag.loh_enable = config.loh_enable;
  featureFlag.google_chat_notification_enable =
    config.google_chat_notification_enable;
  featureFlag.microsoft_teams_notification_enable =
    config.microsoft_teams_notification_enable;
  featureFlag.contract_ddoc = config.contract_setting.ddoc_enable;
  featureFlag.contract_ddoc_enable_feature_26 =
    config.contract_setting.ddoc_enable;
  featureFlag.contract_ddoc_enable_feature_28 =
    config.contract_setting.ddoc_enable;
  featureFlag.contract_timestamp_memo_enable =
    config.contract_setting.timestamp_memo_enable;
  featureFlag.contract_sms_otp =
    config.contract_setting.sms_otp_enable;
  featureFlag.contract_email_otp =
    config.contract_setting.email_otp_enable;
  featureFlag.contract_ndid = config.contract_setting.ndid_enable;
  featureFlag.contract_thaicom =
    config.contract_setting.thaicom_enable;
  featureFlag.memo_save_all_version_history =
    config.memo_save_all_version_history;

  platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .catch((err) => console.error(err));
})();
